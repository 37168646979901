export const formatMonthAndYear = (month: number, year: number) => {
  switch (month) {
    case 1:
        return "Jan " + year;
    case 2:
        return "Fev " + year;
    case 3:
        return "Mar " + year;
    case 4:
        return "Abr " + year;
    case 5:
        return "Mai " + year;
    case 6:
        return "Jun " + year;
    case 7:
        return "Jul " + year;
    case 8:
        return "Ago " + year;
    case 9:
        return "Set " + year;
    case 10:
        return "Out " + year;
    case 11:
        return "Nov " + year;
    case 12:
        return "Dez " + year;
    default:
        return "Invalid Month";
    }
}

export const formatFullMonthAndYear = (month: number, year: number) => {
    switch (month) {
      case 1:
          return "Janeiro " + year;
      case 2:
          return "Fevereiro " + year;
      case 3:
          return "Março " + year;
      case 4:
          return "Abril " + year;
      case 5:
          return "Maio " + year;
      case 6:
          return "Junho " + year;
      case 7:
          return "Julho " + year;
      case 8:
          return "Agosto " + year;
      case 9:
          return "Setembro " + year;
      case 10:
          return "Outubro " + year;
      case 11:
          return "Novembro " + year;
      case 12:
          return "Dezembro " + year;
      default:
          return "Invalid Month";
      }
  }

export function formatDateTransition(dateStr: string) {
    const months = [
        "Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
        "Jul", "Ago", "Set", "Out", "Nov", "Dez"
    ];

    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.getMonth();

    const monthAux = months[month];

    return `${day}/${monthAux}`;
}

export function returnDay(dateStr: string): string {
    const day = dateStr.split('-');
    return day[2].split('T')[0];
}

export function formatMonth(dateStr: string): string {
    const months = [
        "Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
        "Jul", "Ago", "Set", "Out", "Nov", "Dez"
    ];

    const month = dateStr.split('-');
    return months[Number(month[1]) - 1];
}

export function formatDateTransitionYear(dateStr: string) {
    const months = [
        "Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
        "Jul", "Ago", "Set", "Out", "Nov", "Dez"
    ];

    const split = dateStr.split("-");
    const year = split[0];
    const month = parseInt(split[1]);
    const day = split[2];
    const monthAux = months[month - 1];

    return `${day.split('T')[0]}/${monthAux}/${year}`;
}

export function getWeek(dateStr: string) {
    const daysWeek = ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"];

    const split = dateStr.split("-");
    const year = parseInt(split[0]);
    const month = parseInt(split[1]);
    const day = parseInt(split[2]);

    const date = new Date(year, month - 1, day);

    const dayWeekNumber = date.getDay();
    const dayWeek = daysWeek[dayWeekNumber];

    return dayWeek;
}

export function formatDateHour(dateStr: string) {
    const data = new Date(dateStr);

    const day = String(data.getDate()).padStart(2, '0');
    const month = String(data.getMonth() + 1).padStart(2, '0'); // Os meses em JavaScript vão de 0 a 11
    const year = data.getFullYear();
    const hours = String(data.getHours()).padStart(2, '0');
    const minutes = String(data.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
}
