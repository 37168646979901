import styled from 'styled-components';

export const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 34px;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 50px 50px 0 0;
  z-index: 21;

  @media (max-width: 820px) {
    height: 50px;

    &.Terminais {
      position: fixed;
    }
  }

  @media (max-width: 600px) {
    border-radius: 20px 20px 0 0;
  }
`;

export const Content = styled.main<{ offsetMenu: boolean }>`
    position: relative;
    transition: top 0.6s;
    min-height: 80vh;
    padding-bottom: 50px;
    margin: 0 32px 0;

    @media screen and (max-width: 820px) {
        padding-bottom: 70px;
    }

    @media screen and (max-width: 767px) {
        margin: 0 12px 0;
    }
`;
