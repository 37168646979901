import styled, { createGlobalStyle, keyframes } from 'styled-components';
import ArrowLeftBlue from './assets/icons/arrow-left-blue-icon.svg';
import ArrowLeftGreen from './assets/images/left-green-icon.svg';
import { themeAPP } from './constants';
import { Colors } from './theme/colors';
import { isMonetix } from './utils';

const arrowIcon = isMonetix(themeAPP) ? ArrowLeftBlue : ArrowLeftGreen;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const loadUp = keyframes`
  0% {
    transform: translateY(120px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const TrackingInExpand = keyframes`
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }
  
  100% {
    opacity: 1;
  }
`;

export const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export type MyProps = {
  theme: {
    colors: Colors;
  };
};

export const GlobalStyle = createGlobalStyle<MyProps>`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  body {
    background: ${({ theme }) => theme.colors.background};
    height: 100%;
  }
  img {
    display: block;
    max-width: 100%;
    user-select: none;
  }
  *::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
  }
  *::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.background};
  }
  *::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
  }
  .DayPicker {
    font-family: 'Roboto';
    position: absolute;
    opacity: 0;
    right: 0;
    top: 40px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    animation: ${fadeIn} .5s ease forwards;
    z-index: 1;
    background-color: #FFF;
    border-radius: 6px;
    z-index: 23;
    @media screen and (max-width: 820px) {
      top: unset;
      bottom: 48px;
    }
    .DayPicker-Caption {
      margin-bottom: 18px;
      > div {
        font-weight: 600;
      }
    }
  }
  .DayPicker-NavButton {
    background-image: url(${arrowIcon}) !important;
    margin-right: unset;
    &.DayPicker-NavButton--prev {
      left: 22px;
    }
    &.DayPicker-NavButton--next {
      right: 22px;
      transform: rotate(180deg);
    }
  }
  .DayPicker-Caption > div {
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.primary};
  }
  .DayPicker-Day,
  .DayPicker-Weekday {
    font-family: 'Roboto';
    text-transform: uppercase;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.lightGray};
    margin: 5px;
  }
  .DayPicker-Weekday {
    padding: 5px !important;
    color: ${({ theme }) => theme.colors.lightGray} !important;
    abbr[title] {
        font-weight: bold;
    }
  }
  .DayPicker-Week {
    .DayPicker-Day:first-child {
      border-radius: 50% 0 0 50% !important;
    }
    .DayPicker-Day:last-child {
      border-radius: 0 50% 50% 0 !important;
    }
    .DayPicker-Day.DayPicker-Day--start.DayPicker-Day--selected,
    .DayPicker-Day.DayPicker-Day--end.DayPicker-Day--selected {
      border-radius: 50% !important;
    }
  }
  .DayPicker-Day {
    padding: 10px !important;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: ${({ theme }) => theme.colors.primary} !important;

    &:hover {
      background-color: #4A90E2 !important;
    }
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: ${({ theme }) => theme.colors.primary} !important;
    color: #FFF;
    border-radius: 50%;
  }

  button:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

export const FlipCard = styled.div`
  background-color: transparent;
  width: 25px;
  height: 25px;
  border: none;
  perspective: 1000px;
`;

export const FlipCardInner = styled.div<{ rotateCard: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  transform: ${(props) => (props.rotateCard ? 'rotateY(180deg)' : 'rotateY(0deg)')};
`;

export const FlipCardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const FlipCardBack = styled(FlipCardFront)`
  transform: rotateY(180deg);
`;
