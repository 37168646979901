import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

export const Container = styled.div<{ open: boolean }>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 20;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primaryTransparent};
  -webkit-tap-highlight-color: transparent;
  opacity: ${(props) => (props.open ? '1' : '0')};
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: ${({ theme }) => theme.colors.white};

  svg {
    display: block;

    circle {
      animation-duration: 1.4s;
      animation-timing-function: ease-in-out;
      animation-delay: 0s;
      animation-iteration-count: infinite;
      animation-direction: normal;
      animation-fill-mode: none;
      animation-play-state: running;
      stroke-dasharray: 80px, 200px;
      stroke-dashoffset: 0px;
      stroke: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const IconContainer = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  animation-duration: 1.4s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: ${rotate};
`;
