/* eslint-disable import/no-cycle */
import { colors, colorsMonetix, colorsUnimedPay } from './colors';

export const themeMonetix = {
  colors: colorsMonetix,
};

export const themeUnimedPay = {
  colors: colorsUnimedPay,
};

export { colors };
