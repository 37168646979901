export const APP_TOKEN = '@q2-dashboard';
export const APP_TOKEN_EC = '@q2-dashboard-ec';
export const APP_TOKEN_ECLIST = '@q2-dashboard-ec-list';
export const APP_TOKEN_FIRST_ACCESS = '@q2-dashboard-first-access';

export const FlexRate = Object.freeze({
  x000: 0, // free
  x025: 25,
  x050: 50,
  x075: 75,
  x100: 100, // full
});

export const Months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const WeekDaysShort = [
  'Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab',
];

export const WeekDaysLong = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
];

export const TaxesMonetixSorted = [
  { installment: 'Crédito 1x (D+15)', percentage: 4.29, lightBlue: false },
  { installment: 'Crédito 2x (D+15)', percentage: 6.38, lightBlue: true },
  { installment: 'Crédito 3x (D+15)', percentage: 7.43, lightBlue: false },
  { installment: 'Crédito 4x (D+15)', percentage: 8.42, lightBlue: true },
  { installment: 'Crédito 5x (D+15)', percentage: 9.37, lightBlue: false },
  { installment: 'Crédito 6x (D+15)', percentage: 10.36, lightBlue: true },
  { installment: 'Crédito 7x (D+15)', percentage: 11.39, lightBlue: false },
  { installment: 'Crédito 8x (D+15)', percentage: 12.37, lightBlue: true },
  { installment: 'Crédito 9x (D+15)', percentage: 13.34, lightBlue: false },
  { installment: 'Crédito 10x (D+15)', percentage: 14.32, lightBlue: true },
  { installment: 'Crédito 11x (D+15)', percentage: 14.87, lightBlue: false },
  { installment: 'Crédito 12x (D+15)', percentage: 15.98, lightBlue: true },
];

export const TaxesMonetix = [
  { installment: 'Crédito 1x (D+15)', percentage: 4.29, lightBlue: false },
  { installment: 'Crédito 7x (D+15)', percentage: 11.39, lightBlue: true },
  { installment: 'Crédito 2x (D+15)', percentage: 6.38, lightBlue: true },
  { installment: 'Crédito 8x (D+15)', percentage: 12.37, lightBlue: false },
  { installment: 'Crédito 3x (D+15)', percentage: 7.43, lightBlue: false },
  { installment: 'Crédito 9x (D+15)', percentage: 13.34, lightBlue: true },
  { installment: 'Crédito 4x (D+15)', percentage: 8.42, lightBlue: true },
  { installment: 'Crédito 10x (D+15)', percentage: 14.32, lightBlue: false },
  { installment: 'Crédito 5x (D+15)', percentage: 9.37, lightBlue: false },
  { installment: 'Crédito 11x (D+15)', percentage: 14.87, lightBlue: true },
  { installment: 'Crédito 6x (D+15)', percentage: 10.36, lightBlue: true },
  { installment: 'Crédito 12x (D+15)', percentage: 15.98, lightBlue: false },
];

export const periodArray: string[] = [
  'Mês atual',
  'Últimos 30 dias',
  'Últimos 15 dias',
  'Últimos 7 dias',
  'Ontem',
  'Hoje',
];

export const franchisesAutoApproval = [20, 80, 82, 84, 89, 106];
