import React from 'react';

import { useAuth } from '../hooks/AuthContext';
import api from '../services/api';
import {
  getEc, getUser, setEc, setUser,
} from '../services/user';
import PrivateRoutes from './private-routes';
import PublicRoutes from './public-routes';

const Routes: React.FC = () => {
  const {
    allowMonetix, authenticated, setAllowMonetix, loading, ecListState,
  } = useAuth();

  if (loading) {
    return <></>;
  }

  if (authenticated && ecListState.length === 1) {
    setEc(ecListState[0]);
  }

  if (allowMonetix < 1 && authenticated) {
    const ec = getEc();
    const getLinkStatus = () => {
      if (!ec?.Id) return;

      api
        .get('/checkq2linkstatus', {
          params: {
            ecId: ec?.Id,
          },
        })
        .then((res) => {
          const { q2LinkStatus, requestId } = res.data;
          setAllowMonetix(q2LinkStatus);
          if (requestId) {
            setUser({
              ...getUser(),
              requestId,
            });
          }
        });
    };

    getLinkStatus();
  }

  return authenticated ? <PrivateRoutes /> : <PublicRoutes />;
};

export default Routes;
