import React, { createContext, useState } from 'react';
import { SplitMovement, SplitReceivers } from '../../services/split/type';
import {
  SplitDataContextData, SplitDataProviderProps, SplitPaginationInfo,
  ReceiverFilterProps, TransactionFilterProps,
} from './types';
import { Modifier } from '../../pages/Split/types';

export const SplitDataContext = createContext<SplitDataContextData>(
  {} as SplitDataContextData,
);

export function SplitDataProvider({ children }: SplitDataProviderProps) {
  const [dateSelected, setDateSelected] = useState<Modifier>({
    from: undefined,
    to: undefined,
  });
  const [enablePagination, setEnablePagination] = useState<boolean>(true);
  const [updateReceiver, setUpdateReceiver] = useState<boolean>(false);
  const [paginationInfo, setPaginationInfo] = useState<SplitPaginationInfo>({
    count: 1,
    currentPage: 1,
    limit: 10,
    totalCount: 1,
    totalPages: 1,
  });
  const [receiverFilter, setReceiverFilter] = useState<ReceiverFilterProps>({
    aliasName: '',
    document: '',
    receiverName: '',
  });
  const [transactionFilter, setTransactionFilter] = useState<TransactionFilterProps>({
    conciliationCode: '',
    serialNumber: '',
    movementHash: '',
  });
  const [activeTab, setActiveTab] = useState<string>('transactions');
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);
  const [deleteReceiver, setDeleteReceiver] = useState<SplitReceivers>();
  const [splitDetail, setSplitDetail] = useState<SplitMovement>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showSplitDetailsModal, setShowSplitDetailsModal] = useState<boolean>(false);
  const [activePeriod, setActivePeriod] = useState<number>(0);
  const [selectedDeleteReceiver, setSelectedDeleteReceiver] = useState<SplitReceivers | null>(null);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [loadingExcel, setLoadingExcel] = useState<boolean>(false);

  const [flexRate, setFlexRate] = useState<number>();

  return (
    <SplitDataContext.Provider
      value={{
        loadingExcel,
        setLoadingExcel,
        isFilterOpen,
        setIsFilterOpen,
        isTableLoading,
        setIsTableLoading,
        flexRate,
        setFlexRate,
        activeTab,
        setActiveTab,
        dateSelected,
        setDateSelected,
        paginationInfo,
        setPaginationInfo,
        enablePagination,
        setEnablePagination,
        deleteReceiver,
        setDeleteReceiver,
        showDeleteModal,
        setShowDeleteModal,
        selectedDeleteReceiver,
        setSelectedDeleteReceiver,
        setActivePeriod,
        activePeriod,
        updateReceiver,
        setUpdateReceiver,
        receiverFilter,
        setReceiverFilter,
        transactionFilter,
        setTransactionFilter,
        splitDetail,
        setSplitDetail,
        showSplitDetailsModal,
        setShowSplitDetailsModal,
      }}
    >
      {children}
    </SplitDataContext.Provider>
  );
}
