interface MonthYear {
  month: number;
  year: number
}

export function getFirstAndLastDateOfMonth({ month, year }: MonthYear): { firstDate: Date, lastDate: Date } {
  const lastDate = new Date(year, month, 0);
  const firstDate = new Date(year, month - 1);
  
  return { firstDate, lastDate };
}

export function get8MonthsForward(date: Date): {from: Date, to: Date} {
  const from = new Date(date.getFullYear(), date.getMonth(), 1);
  const to = new Date(date.getFullYear(), date.getMonth() + 8, 1);
  to.setDate(to.getDate() - 1);

  return { from, to };
}

export function get8MonthsAgo(date: Date): {from: Date, to: Date} {
  const from = new Date(date.getFullYear(), date.getMonth() - 7, 1);
  const to = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  to.setDate(to.getDate() - 1);

  return { from, to };
}