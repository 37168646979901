import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import BackdropLoading from '../components/BackdropLoading';
import Main from '../components/Main';
import Route from './Route';

import { SplitDataProvider } from '../context/useSplitData';

const EcSelection = lazy(() => import('../pages/Login/Step1'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const NotFound = lazy(() => import('../pages/NotFound'));
const PaymentTerminals = lazy(() => import('../pages/PaymentTerminals'));
const MyDataAndTerminals = lazy(() => import('../pages/MyDataAndTerminals'));
const MonetixMagnifyingGlass = lazy(() => import('../pages/MonetixMagnifyingGlass'));
const Monetix = lazy(() => import('../pages/MonetixLink'));
const Split = lazy(() => import('../pages/Split'));
const Receiver = lazy(() => import('../pages/Receiver'));
const Help = lazy(() => import('../pages/Help'));
const MyData = lazy(() => import('../pages/MyData'));
const ResetPasswordStep1 = lazy(() => import('../pages/PasswordReset/Step1'));
const ResetPasswordStep2 = lazy(() => import('../pages/PasswordReset/Step2'));
const RedefinitionPassword = lazy(() => import('../pages/PasswordRedefinition'));
const SchenduleReceivable = lazy(() => import('../pages/SchenduleReceivables'));

const PrivateRoutes: React.FC = () => (
  <BrowserRouter>
    <Suspense fallback={<Main><BackdropLoading open /></Main>}>
      <Switch>
        <Route exact path="/" component={EcSelection} title="Seleção EC" />
        <Route exact path="/dashboard" component={Dashboard} title="DashBoard" />
        <Route exact path="/payment-terminals" component={PaymentTerminals} title="Terminais de pagamento" />
        <Route exact path="/payment-terminals-my-data" component={MyDataAndTerminals} title="Meus Dados e Terminais de pagamento" />
        <Route exact path="/magnifying-glass" component={MonetixMagnifyingGlass} title="Lupa" />
        <Route exact path="/extract" component={MonetixMagnifyingGlass} title="Extrato" />
        <Route exact path="/schendule-receivable" component={SchenduleReceivable} title="Agenda de Recebiveis" />
        <Route exact path="/link" component={Monetix} title="Monetix" />
        <Route exact path="/receiver/create" component={Receiver} title="Criar Recebedor" />
        <Route exact path="/receiver/edit" component={Receiver} title="Editar Recebedor" />
        <Route exact path="/help" component={Help} title="Ajuda" />
        <Route exact path="/my-data" component={MyData} title="Meus Dados" />
        <Route exact path="/reset-password-step1" component={ResetPasswordStep1} title="Reset Password" />
        <Route exact path="/reset-password-step2" component={ResetPasswordStep2} title="Reset Password" />
        <Route exact path="/password-redefinition" component={RedefinitionPassword} title="Redefine Password" />
        <Route exact path="/split" title="split">
          <SplitDataProvider>
            <Split />
          </SplitDataProvider>
        </Route>
        <Route path="*" component={NotFound} />
      </Switch>
    </Suspense>
  </BrowserRouter>
);
export default PrivateRoutes;
