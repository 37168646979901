import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ReceivablesSchendule from '../../assets/icons/calendar.svg';
import closeMenu from '../../assets/icons/close-menu.svg';
import closeMenuGreen from '../../assets/icons/close-menuGreen.svg';
import dashboardPrimaryGreen from '../../assets/icons/dashboard-primary-green.svg';
import dashboardPrimary from '../../assets/icons/dashboard-primary.svg';
import dashboard from '../../assets/icons/dashboard.svg';
import exitUnimed from '../../assets/icons/exitUnimed.svg';
import ExtractIconGreenMobile from '../../assets/icons/extract-icon-green-mobile.svg';
import ExtractIconGreen from '../../assets/icons/extract-icon-green.svg';
import helpPrimaryGreen from '../../assets/icons/help-primary-green.svg';
import helpPrimary from '../../assets/icons/help-primary.svg';
import help from '../../assets/icons/help.svg';
import IconLinkGreen from '../../assets/icons/icon-link-green.svg';
import IconLink from '../../assets/icons/icon-link.svg';
import magnifyingGlassPrimaryIcon from '../../assets/icons/magnifying-glass-primary.svg';
import magnifyingGlassIcon from '../../assets/icons/magnifying-glass.svg';
import menuMobile from '../../assets/icons/menu-mobile.svg';
import myData from '../../assets/icons/my-data-icon.svg';
import myDataMenu from '../../assets/icons/my-data.svg';
import newLinq2Blue from '../../assets/icons/new-q2link-blue.svg';
import newMonetix from '../../assets/icons/new-q2link-white.svg';
import paymentTerminalsPrimaryGreen from '../../assets/icons/payment-terminals-primary-green.svg';
import paymentTerminalsPrimary from '../../assets/icons/payment-terminals-primary.svg';
import paymentTerminals from '../../assets/icons/payment-terminals.svg';
import signOutPrimaryGreen from '../../assets/icons/sign-out-primary-green.svg';
import signOutPrimary from '../../assets/icons/sign-out-primary.svg';
import signOut from '../../assets/icons/sign-out.svg';
import splitIcon from '../../assets/icons/splitIcon.svg';
import splitIconMobile from '../../assets/icons/splitIconMobile.svg';
import warning from '../../assets/icons/warning-alert.svg';
import { themeAPP } from '../../constants';
import { fadeIn, loadUp } from '../../styles';
import { isMonetix } from '../../utils';

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.primary};
  height: 100px;
  border-radius: 0 0 20px 20px;
  padding: 0 40px;
  z-index: 4;
  position: relative;
  left: 0;
  right: 0;

  @media (min-width: 600px) {
    margin: 0 20px;
  }

  a {
    text-decoration: none;
  }

  @media (max-width: 600px) {
    height: 80px;
  }
`;

export const CardMenuMobile = styled.div<{ open: boolean }>`
  position: absolute;
  display: none;
  min-width: 300px;
  top: 80px;
  left: 50%;
  transform: ${(props) => (props.open ? 'translate(-50%, 0)' : 'translate(-50%, -100%)')};
  transition: transform 0.6s ease-in-out;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0 0 20px 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  padding: 24px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  z-index: 3;

  a {
    text-decoration: none;
  }

  @media (max-width: 600px) {
    display: grid;
  }

  @media (max-width: 390px) {
    padding: 8px;
  }
`;

export const LogoContainer = styled(Link)`
  width: 145px;
  height: fit-content;
`;

export const MenuContainer = styled.nav<{ hasMonetix: boolean }>`
  display: none;
  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: ${({ hasMonetix }) => (hasMonetix ? 'repeat(6, 1fr)' : 'repeat(5, 1fr)')};
    grid-template-rows: 1fr;
    grid-gap: 30px;
  }
  @media (min-width: 600px) and (max-width: 940px) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
  }
`;

export const MenuItem = styled.span`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17px;
  transition: all 0.3s ease-in;
  animation-direction: alternate;
  :active {
    transform: scale(0.9);
  }
`;

export const MenuItemText = styled.h4`
  color: ${({ theme }) => theme.colors.white};
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 4px;
  max-width: 80px;
  text-align: initial;
`;

export const MenuItemMobileText = styled(MenuItemText)`
  color: ${({ theme }) => theme.colors.primary};
  margin-left: 0;
  text-align: center;
  margin-top: 10px;
`;

export const MobileLink = styled(Link)`
  &.full {
    grid-column-start: 1;
    grid-column-end: span 2;
  }
`;

function handleTranslate(route: string, hasMonetix?: boolean) {
  switch (route) {
    case '/':
      return '0, 0';
    case '/payment-terminals':
      return 'calc(100% + 30px), 0';
    case '/magnifying-glass':
      return 'calc(200% + 60px), 0';
    case '/extract':
      return 'calc(200% + 60px), 0';
    case '/schendule-receivable':
      return 'calc(300% + 80px), 0';
    case '/link':
      return 'calc(300% + 225px), 0';
    case '/split' || '/receiver/edit' || '/receiver':
      return hasMonetix ? 'calc(400% + 120px), 0' : 'calc(300% + 90px), 0';
    case '/help':
      return hasMonetix ? 'calc(500% + 150px), 0' : 'calc(400% + 120px), 0';
    default:
      return '';
  }
}

function handleTranslateResponsive(route: string, hasMonetix?: boolean) {
  switch (route) {
    case '/':
      return '0, 0';
    case '/payment-terminals':
      return '120%, 0';
    case '/magnifying-glass':
      return '260%, 0';
    case '/link':
      return '0, 51px';
    case '/schendule-receivable':
      return '0, 51px';
    case '/help':
      return hasMonetix ? '130%, 51px' : '130%, 51px';
    default:
      return '';
  }
}

export const Selected = styled.div<{ active: string; hasMonetix: boolean }>`
  position: relative;
  top: 39px;
  height: 4px;
  background: ${({ theme }) => theme.colors.lightBlue};
  transition: all 0.5s linear;
  transform: translate(
    ${({ active, hasMonetix }) => handleTranslate(active, hasMonetix)}
  );
  @media (max-width: 940px) {
    transform: translate(
      ${({ active, hasMonetix }) => handleTranslateResponsive(active, hasMonetix)}
    );
  }
  @media (min-width: 600px) and (max-width: 940px) {
    top: 14px;
  }
`;

export const MenuMobile = styled.button`
  display: none;
  cursor: pointer;
  border: none;
  background: none;
  @media (max-width: 600px) {
    display: block;
  }
`;

export const MenuMobileIcon = styled.img.attrs({
  src: menuMobile,
})``;

export const DashboardIcon = styled.img.attrs({
  src: dashboard,
})``;

export const DashboardPrimaryIcon = styled.img.attrs({
  src: dashboardPrimary,
})``;

export const DashboardPrimaryGreenIcon = styled.img.attrs({
  src: dashboardPrimaryGreen,
})``;

export const PaymentTerminalsIcon = styled.img.attrs({
  src: paymentTerminals,
})``;

export const ReceivablesSchenduleIcon = styled.img.attrs({
  src: ReceivablesSchendule,
})``;

export const ExtractIcon = styled.img.attrs({
  src: ExtractIconGreen,
})``;

export const ExtractIconMobile = styled.img.attrs({
  src: ExtractIconGreenMobile,
})``;

export const PaymentLinkIcon = styled.img.attrs({
  src: IconLink,
})`
  width: 24px;
  height: 24px;
`;

export const PaymentLinkIconGreen = styled.img.attrs({
  src: IconLinkGreen,
})`
  width: 24px;
  height: 24px;
`;

export const PaymentTerminalsPrimaryIcon = styled.img.attrs({
  src: paymentTerminalsPrimary,
})``;

export const PaymentTerminalsPrimaryGreenIcon = styled.img.attrs({
  src: paymentTerminalsPrimaryGreen,
})``;

export const MagnifyingGlassIcon = styled.img.attrs({
  src: magnifyingGlassIcon,
})`
  height: 28px;
`;

export const SplitIcon = styled.img.attrs({
  src: splitIcon,
})`
  height: 19px;
`;
export const SplitIconMobile = styled.img.attrs({
  src: splitIconMobile,
})`
  height: 19px;
`;

export const MagnifyingGlassPrimaryIcon = styled.img.attrs({
  src: magnifyingGlassPrimaryIcon,
})`
  height: 28px;
`;

export const MonetixIcon = styled.img.attrs({
  src: newMonetix,
})``;

export const MonetixPrimaryIcon = styled.img.attrs({
  src: newLinq2Blue,
})``;

export const SignOutPrimaryIcon = styled.img.attrs({
  src: signOutPrimary,
})``;

export const SignOutPrimaryGreenIcon = styled.img.attrs({
  src: signOutPrimaryGreen,
})``;

export const CloseMenuIcon = styled.img.attrs({
  src: closeMenu,
})``;

export const CloseMenuGreenIcon = styled.img.attrs({
  src: closeMenuGreen,
})``;

export const HelpIcon = styled.img.attrs({
  src: help,
})``;

export const HelpIconPrimary = styled.img.attrs({
  src: helpPrimary,
})``;

export const HelpIconPrimaryGreen = styled.img.attrs({
  src: helpPrimaryGreen,
})``;

export const MyDataIcon = styled.img.attrs({
  src: myData,
})`
  cursor: pointer;
`;

export const LogoutContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const MyDataButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

export const MyDataMenuIcon = styled.img.attrs({
  src: myDataMenu,
})`
  margin-right: 16px;
  cursor: pointer;
`;

export const SpinnerContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${loadUp} 1s forwards;
`;

export const MyData = styled.ul<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  top: 65px;
  right: 80px;
  width: 260px;
  height: 274px;
  border-radius: 15px 0 15px 15px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  animation: ${fadeIn} 0.5s ease alternate;
  padding: 20px;
  list-style: none;
  overflow: hidden;
`;

export const MyDataContainer = styled.div`
  animation: ${loadUp} 1s forwards;
`;

export const MyDataLi = styled.li<{ bold?: boolean }>`
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const SolicitPasswordButtonContainer = styled.span`
  display: flex;
  justify-content: center;
`;

export const SolictPasswordButton = styled.button`
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 155px;
  color: ${({ theme }) => theme.colors.primary};
  padding: 0 20px;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  :active {
    transform: scale(0.9);
  }
`;

export const MyDataSeparator = styled.li`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  margin: 8px 0;
`;

export const MyDataMinorText = styled.li`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.lightGray};
  line-height: 20px;
`;

export const Warning = styled.img.attrs({
  src: warning,
})``;

export const Logout = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

export const LogoutText = styled.h3`
  text-transform: uppercase;
  font-size: 16px;
  font-weight: lighter;
  color: ${({ theme }) => theme.colors.white};
`;

export const SignOutIcon = styled.img.attrs({
  src: isMonetix(themeAPP) ? signOut : exitUnimed,
})``;

export const LogoutButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  margin: 0 0 3px 6px;
`;

export const MenuItemMobile = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.secondGray};
  border-radius: 10px;
  padding: 20px;
  height: 90px;

  @media (max-width: 390px) {
    padding: 8px;
  }
`;

export const MenuItemMobileDense = styled(MenuItemMobile)`
  padding: 10px;
  height: 36px;
  flex-direction: row;
  justify-content: space-between;

  h4 {
    margin-top: 0;
  }
`;

export const LogoutItemMobileDense = styled(Link)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondGray};
  border-radius: 10px;
  padding: 10px;
  height: 36px;

  h4 {
    margin-top: 0;
  }

  @media (max-width: 390px) {
    padding: 8px;
  }
`;

export const LinkMonetix = styled.button`
  border: none;
  background: rgba(0, 0, 0, 0);
`;
