import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Logo from '../../assets/icons/Logo';
import { themeAPP } from '../../constants';
import { useAuth } from '../../hooks/AuthContext';
import api from '../../services/api';
import { getEc, getUser, setUser } from '../../services/user';
import { isMonetix } from '../../utils';
import {
  CardMenuMobile,
  CloseMenuGreenIcon,
  CloseMenuIcon,
  Container,
  DashboardIcon,
  DashboardPrimaryGreenIcon,
  DashboardPrimaryIcon,
  ExtractIcon,
  ExtractIconMobile,
  // HelpIcon,
  HelpIconPrimary,
  HelpIconPrimaryGreen,
  IconContainer,
  LinkMonetix,
  LogoContainer,
  Logout,
  LogoutButton,
  LogoutContainer,
  LogoutItemMobileDense,
  LogoutText,
  MagnifyingGlassIcon,
  MagnifyingGlassPrimaryIcon,
  MenuContainer,
  MenuItem,
  MenuItemMobile,
  MenuItemMobileDense,
  MenuItemMobileText,
  MenuItemText,
  MenuMobile,
  MenuMobileIcon,
  MobileLink,
  MonetixIcon,
  MonetixPrimaryIcon,
  PaymentLinkIcon,
  PaymentLinkIconGreen,
  PaymentTerminalsIcon,
  PaymentTerminalsPrimaryGreenIcon,
  PaymentTerminalsPrimaryIcon,
  ReceivablesSchenduleIcon,
  Selected,
  SignOutIcon,
  SignOutPrimaryGreenIcon,
  SignOutPrimaryIcon,
  SplitIcon,
  SplitIconMobile,
} from './styles';
import { MyDataInfo } from './types';

const Header: React.FC<{
  openedMenu: (value: boolean) => void;
  closeMenu: boolean;
}> = ({ openedMenu, closeMenu }) => {
  const {
    logout, allowMonetix, setAllowMonetix, ecSelectedState,
  } = useAuth();
  const location = useLocation();
  const { push } = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const [pathName, setPathName] = useState<string>('/');
  const [info, setInfo] = useState<MyDataInfo>({} as MyDataInfo);
  const allowedMonetixArray = [-2, -1, 1, 2, 3, 4];

  const isUnimedPay = !isMonetix(themeAPP);

  const handleRedirectMonetix = async () => {
    const ec = getEc();
    await api
      .get('/checkq2linkstatus', {
        params: {
          ecId: ec.Id,
        },
      })
      .then((res) => {
        const { q2LinkStatus, requestId, q2linkAuthToken } = res.data;
        setAllowMonetix(q2LinkStatus);
        if (requestId) {
          setUser({
            ...getUser(),
            requestId,
          });
        }
        if (q2linkAuthToken) {
          window.open(
            `${
              isMonetix(themeAPP)
                ? `https://link.q2pay.com.br/login/${q2linkAuthToken}`
                : `https://link.unimedpay.com.br/login/${q2linkAuthToken}`
            }`,
            '_blank',
          );
          return;
        }
        logout();
      });
  };

  const handleNavigate = useCallback((route: string) => {
    push(route);
    setOpen(false);
  }, []);

  const getMyData = useCallback(() => {
    const user = getEc();
    api
      .get<MyDataInfo>('/about', {
      params: {
        ecId: user.Id,
        franchiseId: user.FranchiseId,
      },
    })
      .then(({ data }) => {
        setInfo(data);
      });
  }, []);

  const verifyEc = () => {
    const ecSelected = ecSelectedState || getEc();
    if (!ecSelected && location.pathname !== '/') logout();
  };

  useEffect(() => {
    setPathName(location.pathname);
  }, [location]);

  useEffect(() => {
    setOpen(false);
  }, [closeMenu]);

  useEffect(() => {
    openedMenu(open);
  }, [open]);

  useEffect(() => {
    getMyData();
    verifyEc();
  }, []);

  return (
    <>
      <Container>
        <LogoContainer to="/">
          <Logo />
        </LogoContainer>
        <MenuContainer hasMonetix={!!allowMonetix}>
          <Link to="/dashboard">
            <MenuItem>
              <IconContainer>
                <DashboardIcon />
                <MenuItemText>Início</MenuItemText>
              </IconContainer>
              <Selected hasMonetix={!!allowMonetix} active={pathName} />
            </MenuItem>
          </Link>
          {isUnimedPay ? (
            <Link to="/extract">
              <MenuItem>
                <IconContainer>
                  <ExtractIcon />
                  <MenuItemText>Extrato</MenuItemText>
                </IconContainer>
              </MenuItem>
            </Link>
          ) : (
            <Link to="/magnifying-glass">
              <MenuItem>
                <IconContainer>
                  <MagnifyingGlassIcon />
                  <MenuItemText>Extrato</MenuItemText>
                </IconContainer>
              </MenuItem>
            </Link>
          )}
          <Link to="/schendule-receivable">
            <MenuItem>
              <IconContainer>
                <ReceivablesSchenduleIcon />
                <MenuItemText>Agenda de Recebíveis</MenuItemText>
              </IconContainer>
            </MenuItem>
          </Link>
          {allowMonetix === -2 && (
            <Link to="/link">
              <MenuItem>
                <IconContainer>
                  {isUnimedPay ? (
                    <>
                      <PaymentLinkIcon />
                      <MenuItemText>Link de Pagamento</MenuItemText>
                    </>
                  ) : (
                    <>
                      <MonetixIcon />
                      <MenuItemText>Link de Pagamento</MenuItemText>
                    </>
                  )}
                </IconContainer>
              </MenuItem>
            </Link>
          )}
          {allowMonetix === 4 && (
            <Link to="/link">
              <MenuItem>
                <IconContainer>
                  {isUnimedPay ? (
                    <>
                      <PaymentLinkIcon />
                      <MenuItemText>Link de Pagamento</MenuItemText>
                    </>
                  ) : (
                    <>
                      <MonetixIcon />
                      <MenuItemText>Link de Pagamento</MenuItemText>
                    </>
                  )}
                </IconContainer>
              </MenuItem>
            </Link>
          )}
          {allowMonetix === 3 && (
            <Link to="/link">
              <MenuItem>
                <IconContainer>
                  {isUnimedPay ? (
                    <>
                      <PaymentLinkIcon />
                      <MenuItemText>Link de Pagamento</MenuItemText>
                    </>
                  ) : (
                    <>
                      <MonetixIcon />
                      <MenuItemText>Link de Pagamento</MenuItemText>
                    </>
                  )}
                </IconContainer>
              </MenuItem>
            </Link>
          )}
          {allowMonetix === 2 && (
            <Link to="/link">
              <MenuItem>
                <IconContainer>
                  {isUnimedPay ? (
                    <>
                      <PaymentLinkIcon />
                      <MenuItemText>Link de Pagamento</MenuItemText>
                    </>
                  ) : (
                    <>
                      <MonetixIcon />
                      <MenuItemText>Link de Pagamento</MenuItemText>
                    </>
                  )}
                </IconContainer>
              </MenuItem>
            </Link>
          )}
          {allowMonetix === -1 && (
            <Link to="/link">
              <MenuItem>
                <IconContainer>
                  {isUnimedPay ? (
                    <>
                      <PaymentLinkIcon />
                      <MenuItemText>Link de Pagamento</MenuItemText>
                    </>
                  ) : (
                    <>
                      <MonetixIcon />
                      <MenuItemText>Link de Pagamento</MenuItemText>
                    </>
                  )}
                </IconContainer>
              </MenuItem>
            </Link>
          )}
          {allowMonetix === 1 && (
            <LinkMonetix onClick={() => handleRedirectMonetix()}>
              <MenuItem>
                <IconContainer>
                  {isUnimedPay ? (
                    <>
                      <PaymentLinkIcon />
                      <MenuItemText>Link de Pagamento</MenuItemText>
                    </>
                  ) : (
                    <>
                      <MonetixIcon />
                      <MenuItemText>Link de Pagamento</MenuItemText>
                    </>
                  )}
                </IconContainer>
              </MenuItem>
            </LinkMonetix>
          )}
          {info.hasSplit && (
            <Link to="/split">
              <MenuItem>
                <IconContainer>
                  <SplitIcon />
                </IconContainer>
              </MenuItem>
            </Link>
          )}
          <Link to="/help">
            {/* <MenuItem>
              <IconContainer>
                <HelpIcon />
                <MenuItemText>Ajuda</MenuItemText>
              </IconContainer>
            </MenuItem> */}
          </Link>
        </MenuContainer>
        <MenuMobile onClick={() => setOpen(!open)}>
          <MenuMobileIcon />
        </MenuMobile>
        <LogoutContainer>
          <Link to="/payment-terminals-my-data">
            <MenuItem>
              <IconContainer>
                <PaymentTerminalsIcon />
                <MenuItemText>Meus Dados e Terminais</MenuItemText>
              </IconContainer>
            </MenuItem>
          </Link>
          <Logout to="/" onClick={logout} title="Sair para Login">
            <LogoutText>Sair</LogoutText>
            <LogoutButton type="button">
              <SignOutIcon />
            </LogoutButton>
          </Logout>
        </LogoutContainer>
      </Container>
      <CardMenuMobile open={open}>
        <Link className="m1" to="/">
          <MenuItemMobile>
            {isUnimedPay ? (
              <DashboardPrimaryGreenIcon />
            ) : (
              <DashboardPrimaryIcon />
            )}
            <MenuItemMobileText>Início</MenuItemMobileText>
          </MenuItemMobile>
        </Link>
        <Link className="m2" to="/payment-terminals-my-data">
          <MenuItemMobile>
            {isUnimedPay ? (
              <PaymentTerminalsPrimaryGreenIcon />
            ) : (
              <PaymentTerminalsPrimaryIcon />
            )}
            <MenuItemMobileText>Meus Dados e Terminais</MenuItemMobileText>
          </MenuItemMobile>
        </Link>
        <Link
          style={{
            gridRowStart: 2,
          }}
          className="m3"
          to="/magnifying-glass"
        >
          <MenuItemMobile>
            {isUnimedPay ? (
              <>
                <ExtractIconMobile />
                <MenuItemMobileText>Extrato</MenuItemMobileText>
              </>
            ) : (
              <MagnifyingGlassPrimaryIcon />
            )}
          </MenuItemMobile>
        </Link>
        {allowMonetix === -2 && (
          <Link className="m4" to="/link">
            <MenuItemMobile>
              {isUnimedPay ? (
                <>
                  <PaymentLinkIconGreen />
                  <MenuItemMobileText>Link de Pagamento</MenuItemMobileText>
                </>
              ) : (
                <MonetixPrimaryIcon />
              )}
            </MenuItemMobile>
          </Link>
        )}
        {allowMonetix === 4 && (
          <Link className="m4" to="/link">
            <MenuItemMobile>
              {isUnimedPay ? (
                <>
                  <PaymentLinkIconGreen />
                  <MenuItemMobileText>Link de Pagamento</MenuItemMobileText>
                </>
              ) : (
                <MonetixPrimaryIcon />
              )}
            </MenuItemMobile>
          </Link>
        )}
        {allowMonetix === 3 && (
          <Link className="m4" to="/link">
            <MenuItemMobile>
              {isUnimedPay ? (
                <>
                  <PaymentLinkIconGreen />
                  <MenuItemMobileText>Link de Pagamento</MenuItemMobileText>
                </>
              ) : (
                <MonetixPrimaryIcon />
              )}
            </MenuItemMobile>
          </Link>
        )}
        {allowMonetix === 2 && (
          <Link className="m4" to="/link">
            <MenuItemMobile>
              {isUnimedPay ? (
                <>
                  <PaymentLinkIconGreen />
                  <MenuItemMobileText>Link de Pagamento</MenuItemMobileText>
                </>
              ) : (
                <MonetixPrimaryIcon />
              )}
            </MenuItemMobile>
          </Link>
        )}
        {allowMonetix === 1 && (
          <LinkMonetix onClick={() => handleRedirectMonetix()}>
            <MenuItemMobile>
              {isUnimedPay ? (
                <>
                  <PaymentLinkIconGreen />
                  <MenuItemMobileText>Link de Pagamento</MenuItemMobileText>
                </>
              ) : (
                <MonetixPrimaryIcon />
              )}
            </MenuItemMobile>
          </LinkMonetix>
        )}
        {allowMonetix === -1 && (
          <Link className="m4" to="/link">
            <MenuItemMobile>
              {isUnimedPay ? (
                <>
                  <PaymentLinkIconGreen />
                  <MenuItemMobileText>Link de Pagamento</MenuItemMobileText>
                </>
              ) : (
                <MonetixPrimaryIcon />
              )}
            </MenuItemMobile>
          </Link>
        )}
        {info.hasSplit && (
          <MobileLink
            className={allowedMonetixArray.includes(allowMonetix) ? 'full' : ''}
            to="/split"
          >
            <MenuItemMobile>
              <IconContainer>
                <SplitIconMobile />
              </IconContainer>
            </MenuItemMobile>
          </MobileLink>
        )}
        <MenuItemMobileDense
          style={{
            gridRowStart: allowMonetix < 1 ? 3 : 'auto',
          }}
          className="m6"
          onClick={() => handleNavigate('/help')}
        >
          <MenuItemMobileText>Ajuda</MenuItemMobileText>
          {isUnimedPay ? <HelpIconPrimaryGreen /> : <HelpIconPrimary />}
        </MenuItemMobileDense>
        <LogoutItemMobileDense
          style={{
            gridRowStart: allowMonetix < 1 ? 4 : 'auto',
          }}
          to="/"
          className="m5"
          onClick={logout}
        >
          <MenuItemMobileText>Sair</MenuItemMobileText>
          {isUnimedPay ? <SignOutPrimaryGreenIcon /> : <SignOutPrimaryIcon />}
        </LogoutItemMobileDense>
        <MenuItemMobileDense
          style={{
            gridRowStart: allowMonetix < 1 ? 4 : 'auto',
          }}
          className="m6"
          onClick={() => setOpen(false)}
        >
          <MenuItemMobileText>Fechar Menu</MenuItemMobileText>
          {isUnimedPay ? <CloseMenuGreenIcon /> : <CloseMenuIcon />}
        </MenuItemMobileDense>
      </CardMenuMobile>
    </>
  );
};

export default Header;
