import React, { useEffect, useState } from 'react';
import Header from '../Header';

import {
  Content,
  Footer,
} from './styles';

const Main: React.FC<{ title?: string }> = ({ children, title }) => {
  const [offset, setOffset] = useState<boolean>(true);
  const [closeMenu, setCloseMenu] = useState(true);

  useEffect(() => {
    setCloseMenu(true);
  }, [offset]);

  return (
    <>
      <Header closeMenu={closeMenu} openedMenu={(e) => setOffset(e)} />
      <Content tabIndex={0} onFocus={() => setCloseMenu(false)} offsetMenu={offset}>
        {children}
        <Footer className={`footer ${title}`} />
      </Content>
    </>
  );
};

export default Main;
