import React from 'react';

import { Container, IconContainer } from './styles';

const BackdropLoading: React.FC<{ open: boolean }> = ({ open }) => (
  <Container open={open}>
    <IconContainer>
      <svg viewBox="22 22 44 44">
        <circle
          cx="44"
          cy="44"
          r="20.2"
          fill="none"
          strokeWidth="3.6"
        />
      </svg>
    </IconContainer>
  </Container>
);

export default BackdropLoading;
