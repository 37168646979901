import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import Route from './Route';

const UreWelcome = lazy(() => import('../pages/UreWelcome'));
const LoginStep1 = lazy(() => import('../pages/Login/Step1'));
const LoginStep2 = lazy(() => import('../pages/Login/Step2'));
const ResetPasswordStep1 = lazy(() => import('../pages/PasswordReset/Step1'));
const ResetPasswordStep2 = lazy(() => import('../pages/PasswordReset/Step2'));
const RedefinitionPassword = lazy(() => import('../pages/PasswordRedefinition'));
const NotFound = lazy(() => import('../pages/NotFound'));

const PublicRoutes: React.FC = () => (
  <BrowserRouter>
    <Suspense fallback={<></>}>
      <Switch>
        <Route exact path="/" component={LoginStep1} title="Login" />
        <Route exact path="/login-step-2" component={LoginStep2} title="Login" />
        <Route exact path="/youre-welcome" component={UreWelcome} title="You are welcome" />
        <Route exact path="/reset-password-step1" component={ResetPasswordStep1} title="Reset Password" />
        <Route exact path="/reset-password-step2" component={ResetPasswordStep2} title="Reset Password" />
        <Route exact path="/password-redefinition" component={RedefinitionPassword} title="Redefine Password" />
        <Route path="*" component={NotFound} title="Not Found" />
      </Switch>
    </Suspense>
  </BrowserRouter>
);

export default PublicRoutes;
