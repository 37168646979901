import {
  APP_TOKEN,
  APP_TOKEN_EC,
  APP_TOKEN_ECLIST,
  APP_TOKEN_FIRST_ACCESS,
} from '../utils/constants';

export interface Ec {
  Id: number;
  EventName: string;
  EventStatus: string;
  FranchiseId: number;
  FranchiseName: string;
  MonetixStatus: number;
  Cnpj: string;
  IsSubAcquirer?: 0 | 1;
}

export interface UserFirstAccess {
  isFirstAccess: boolean;
  document: string;
}

interface User {
  name: string;
  token: string;
  q2linkAuthToken?: string;
  requestId?: number;
  ecs?: Ec[];
}

const setUser = (user: User) => {
  localStorage.setItem(APP_TOKEN, JSON.stringify(user));
};

const setUserFirstAccess = (firstAccess: UserFirstAccess) => {
  localStorage.setItem(APP_TOKEN_FIRST_ACCESS, JSON.stringify(firstAccess));
};

const setEc = (ec: Ec) => {
  localStorage.setItem(APP_TOKEN_EC, JSON.stringify(ec));
};

const setEcList = (ecs: Ec[]) => {
  localStorage.setItem(APP_TOKEN_ECLIST, JSON.stringify(ecs));
};

// Funções para obter dados do localStorage
const getUser = (): User | null => {
  const data = localStorage.getItem(APP_TOKEN);
  return data ? JSON.parse(data) : null;
};

const getUserFirstAccess = (): UserFirstAccess | null => {
  const data = localStorage.getItem(APP_TOKEN_FIRST_ACCESS);
  return data ? JSON.parse(data) : null;
};

const getEc = (): Ec | null => {
  const data = localStorage.getItem(APP_TOKEN_EC);
  return data ? JSON.parse(data) : null;
};

const getEcList = (): Ec[] | null => {
  const data = localStorage.getItem(APP_TOKEN_ECLIST);
  return data ? JSON.parse(data) : null;
};

const removeUser = () => {
  localStorage.removeItem(APP_TOKEN);
  localStorage.removeItem(APP_TOKEN_EC);
  localStorage.removeItem(APP_TOKEN_ECLIST);
  localStorage.removeItem(APP_TOKEN_FIRST_ACCESS);
};

const getToken = (): string | null => {
  const user = getUser();
  return user?.token || null;
};

export {
  getEc,
  getEcList,
  getToken,
  getUser,
  getUserFirstAccess,
  removeUser,
  setEc,
  setEcList,
  setUser,
  setUserFirstAccess,
};
